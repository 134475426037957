import React, { useState, useEffect } from 'react'
import { Divider, TextField } from '@mui/material'
import { Controller, useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import { useNavigate } from 'react-router-dom'
import * as yup from 'yup'
import { useSelector, useDispatch } from 'react-redux'
import { getUsers } from 'src/old-app/store/userSlice'
import {
	useCreateAssigneeMutation,
	useCreateDecisionMutation,
	useCreateProtocolDecisionTaskMutation,
	useCreateProtocolHashtagMutation,
	useCreateProtocolMutation,
	useCreateProtocolUsersMutation,
	useSendProtocolMutation,
} from 'src/old-app/service/Protocol'
import ProtocolAddButton from 'src/old-app/components/protocol/form/ProtocolAddButton'
import ProtocolDecision from 'src/old-app/components/protocol/form/ProtocolDecision'
import {
	useCreateTaskDeadlineMutation,
	useCreateTaskMutation,
	useCreateTaskSubscriberMutation,
} from 'src/old-app/service/Tasks'
import { showMessage } from 'src/old-app/store/fuse/messageSlice'
import ProtocolCreateHeader from 'src/old-app/components/protocol/form/ProtocolCreateHeader'
import { interfaceActions } from 'src/old-app/store/interfaceSlice'
import moment from 'moment'
import { useCreateUserProjectMutation } from 'src/old-app/service/Projects'
import { InputContainer, UsersChoice } from '../../../shared/components'
import ProjectsChoice from '../../../shared/components/projects-choice'
import PlacesChoice from '../../../shared/components/places-choice'
import ProtocolTagsChoice from '../../../shared/components/protocol-tags-choice'
import { getID } from '../../../shared/config/fields-ids'
import PageWithSideBar from '../../containers/PageWithSideBar'
import { ProjectsTable } from '../../../entities/project'
import ProjectSideBarCheck from '../../components/project/ProjectSideBarCheck'
import { useTranslation } from 'react-i18next'
import DatePickerInput from 'src/shared/components/date-picker-input/DatePickerInput'
import dayjs from 'dayjs'

export const decisionsTakenDecision = {
	type: 'decision',
	value: '',
	deadline: undefined,
	hasDeadline: false,
}

function ProtocolCreate() {
	const { t } = useTranslation()
	const schema = yup.object().shape({
		topic: yup.string().required(t('meeting_theme_title_is_required')),
		meeting_date: yup.date().required(t('meeting_date_is_required')).typeError(t('invalid_date')),
		meeting_time_start: yup.string().required(t('meeting_start_time_is_required')),
		meeting_time_end: yup.string().required(t('meeting_finish_time_is_required')),
		users: yup.array().min(1, t('participant_is_required')),
		place: yup.object().required(t('meeting_location_is_required')).nullable(),
		decisionsTaken: yup
			.array()
			.min(1, t('decision_is_required'))
			.of(
				yup.object().shape({
					value: yup.string().required(t('title_is_required')),
					hasDeadline: yup.boolean().default(false),
					deadline: yup
						.date()
						.nullable()
						.default(undefined)
						.when('hasDeadline', {
							is: true,
							then: yup.date().required(t('deadline_is_required')).typeError(t('invalid_date')),
						}),
				})
			),
	})
	const dispatch = useDispatch()
	const navigate = useNavigate()
	const users = useSelector(getUsers)
	const {
		handleSubmit,
		register,
		control,
		getValues,
		setError,
		formState: { errors },
		watch,
	} = useForm({
		defaultValues: {
			users: [],
			place: null,
			project: null,
			decisionsTaken: [],
			meeting_date: undefined,
			meeting: '',
			meeting_time_end: '',
			meeting_time_start: '',
			topic: '',
			hashtags: [],
		},
		resolver: yupResolver(schema),
	})

	const [createProtocol, protocolResult] = useCreateProtocolMutation()
	const [createProtocolHashtag, ProtocolHashtagResult] = useCreateProtocolHashtagMutation()
	const [createProtocolUser, protocolUserResult] = useCreateProtocolUsersMutation()
	const [createDecision, decisionResult] = useCreateDecisionMutation()
	const [createTaskDeadline, deadlineResult] = useCreateTaskDeadlineMutation()
	const [createProtocolDecisionTask, protocolDecisionResult] = useCreateProtocolDecisionTaskMutation()
	const [createTask, taskResult] = useCreateTaskMutation()
	const [createAssignee, assigneResult] = useCreateAssigneeMutation()
	const [createTaskSubscriber, subsResult] = useCreateTaskSubscriberMutation()
	const [sendProtocol, sendResult] = useSendProtocolMutation()
	const [isDisabled, setIsDisabled] = useState(false)
	const [createUserrProject, userResult] = useCreateUserProjectMutation()

	useEffect(() => {
		function showErrorMessage(errorDescription = null) {
			dispatch(interfaceActions?.setIsLoading(false))
			setIsDisabled(false)
			dispatch(
				showMessage({
					message: errorDescription ?? t('error'),
					autoHideDuration: 2000,
					anchorOrigin: {
						vertical: 'top',
						horizontal: 'top-right',
					},
					variant: 'error',
				})
			)
		}
		if (protocolResult?.isError) {
			showErrorMessage(protocolResult?.error?.data?.error)
		}
		if (protocolUserResult?.isError) {
			showErrorMessage(protocolUserResult?.error?.data?.error)
		}
		if (decisionResult?.isError) {
			showErrorMessage(decisionResult?.error?.data?.error)
		}
		if (deadlineResult?.isError) {
			showErrorMessage(deadlineResult?.error?.data?.error)
		}
		if (protocolDecisionResult?.isError) {
			showErrorMessage(protocolDecisionResult?.error?.data?.error)
		}
		if (taskResult?.isError) {
			showErrorMessage(taskResult?.error?.data?.error)
		}
		if (sendResult?.isError) {
			showErrorMessage(sendResult?.error?.data?.error)
		}
		if (assigneResult?.isError) {
			showErrorMessage(assigneResult?.error?.data?.error)
		}
	}, [
		sendResult,
		assigneResult,
		taskResult,
		protocolDecisionResult,
		deadlineResult,
		decisionResult,
		protocolUserResult,
		protocolResult,
	])

	const sendProtocolByEmail = async (id) => {
		dispatch(
			showMessage({
				message: t('successfully_created.protocol'),
				autoHideDuratigon: 1000,
				anchorOrigin: {
					vertical: 'top',
					horizontal: 'top-right',
				},
				variant: 'success',
			})
		)
		navigate('/protocol')
		setTimeout(() => {
			sendProtocol(id)
		}, 1000)
	}

	const createProtocolHandler = async (data) => {
		try {
			dispatch(interfaceActions.setIsLoading(true))
			setIsDisabled(true)

			const userIds = data.users.map((user) => {
				return { id: user.id }
			})

			const protocolDecisionTasks = []
			await Promise.all(
				await data.decisionsTaken.map(async (dt, index) => {
					let createDecisionData = {}
					let createTaskData = []

					if (dt.type === 'decision') {
						const decisionData = await createDecision({ decision: dt.value })
						createDecisionData = decisionData.data.data

						const createProtocolDecisionTaskData = {
							decision_id: createDecisionData?.id,
							task_id: null,
						}

						protocolDecisionTasks.push({ order: dt.order, item: createProtocolDecisionTaskData })
					}

					if (dt.type === 'task') {
						const assigneeIds = dt?.users.map((item) => item.id)

						if (dt?.project?.id) {
							await Promise.all(
								assigneeIds.map(async (assigneeId) => {
									await createUserrProject({
										project_id: dt?.project?.id,
										user_id: assigneeId,
									})
								})
							)
						}

						const taskDeadline = dt?.deadline ? dayjs(dt?.deadline).format() : undefined

						const taskData = await createTask({
							title: dt.value,
							assignee_id: assigneeIds,
							task_priority_id: dt?.priority?.id,
							project_id: dt?.project?.id,
							need_report: dt?.need_report,
							contract_type: dt?.contract_directory?.id,
							deadline: taskDeadline,
						})

						createTaskData = taskData?.data?.data

						if (dayjs(taskDeadline).isValid()) {
							createTaskData?.map(async (item) => {
								await Promise.all(
									data.users.map(async (user) => {
										await createTaskSubscriber({ task_id: item?.id, user_id: user?.id })
									})
								)
							})
						}

						createTaskData.map((item) => {
							const createProtocolDecisionTaskData = {
								decision_id: item.id,
								assignee_id: item.assignee_id,
							}
							protocolDecisionTasks.push({ order: dt.order, item: createProtocolDecisionTaskData })
						})
					}
				})
			)

			const protocolDecisionTasksArray = protocolDecisionTasks.sort((a, b) => a.order - b.order).map((el) => el.item)

			const protocolData = {
				meeting_date_start: dayjs(
					`${dayjs(data.meeting_date).format('YYYY-MM-DD')}T${data?.meeting_time_start}:00`
				).format(),
				meeting_date_end: dayjs(
					`${dayjs(data.meeting_date).format('YYYY-MM-DD')}T${data?.meeting_time_end}:00`
				).format(),
				place: data?.place?.id,
				topic: data?.topic,
				project_id: data?.project?.id,
				protocol_task_decision: protocolDecisionTasksArray.map((item) => ({
					id: item.decision_id,
					assignee_id: item.assignee_id,
				})),
				protocol_users: userIds,
			}

			const res = await createProtocol(protocolData)
			const protocolId = res?.data?.data

			if (!protocolId) return

			await Promise.all(
				data.hashtags.map(async (hashtag) => {
					await createProtocolHashtag({ id: protocolId, hashtagId: hashtag.id })
				})
			)
			// await createProtocolDecisionTask({ task_decision_protocol_links: protocolDecisionTasksArray })

			await sendProtocolByEmail(protocolId)
			dispatch(interfaceActions.setIsLoading(false))
		} catch (error) {
			dispatch(interfaceActions?.setIsLoading(false))
			setIsDisabled(false)
			dispatch(
				showMessage({
					message: error?.message ?? t('error'),
					autoHideDuration: 500,
					anchorOrigin: {
						vertical: 'top',
						horizontal: 'top-right',
					},
					variant: 'error',
				})
			)
		}
	}

	const project = watch('project')

	return (
		<PageWithSideBar
			contentClassName="tt-bg-gray-50 tt-pt-0 "
			id={getID({ entity: 'project' })}
			content={
				<form onSubmit={handleSubmit(createProtocolHandler)} className="tt-w-full tt-flex tt-flex-col h-full relative ">
					<ProtocolCreateHeader isDisabled={isDisabled} />
					<div className="tt-w-full">
						<div className="tt-max-w-900 tt-mx-auto tt-w-full tt-bg-white  tt-p-32 tt-rounded-lg tt-shadow-sm ">
							<div className="tt-flex tt-flex-col tt-gap-20">
								<InputContainer label={t('meeting_title')} error={errors?.deadline}>
									<TextField
										{...register('topic')}
										helperText={errors?.topic?.message}
										error={!!errors.topic}
										placeholder={t('meeting_title')}
										hiddenLabel
										className="dialig-input"
										id="protocol-topic"
										defaultValue=""
										size="small"
									/>
								</InputContainer>
								<InputContainer label={t('tags')} error={errors?.hashtags}>
									<Controller
										render={({ field: { value, onChange } }) => (
											<ProtocolTagsChoice placeholder={t('tags')} value={value} onChange={onChange} />
										)}
										name="hashtags"
										control={control}
									/>
								</InputContainer>
								<div className="tt-flex tt-gap-16">
									<div className="tt-flex tt-flex-col ">
										<div className="tt-flex tt-gap-8 tt-items-center">
											<InputContainer label={t('meeting_date')} error={errors?.meeting_date}>
												<Controller
													render={({ field: { value, onChange } }) => (
														<DatePickerInput value={value} onChange={onChange} />
													)}
													name="meeting_date"
													control={control}
												/>
											</InputContainer>
										</div>
									</div>
									<div className="tt-flex tt-flex-col ">
										<span className="tt-text-14 tt-font-semibold tt-mb-4">{t('meeting_time')}</span>
										<div className="tt-flex tt-gap-8 tt-items-center">
											<div className="tt-flex tt-items-center tt-gap-8">
												<span className="tt-text-14  tt-mb-4">{t('from')}</span>
												<Controller
													render={({ field: { value, onChange } }) => (
														<TextField
															value={value}
															onChange={onChange}
															helperText={errors?.meeting_time_start?.message}
															error={!!errors.meeting_time_start}
															type="time"
															hiddenLabel
															className="dialig-input"
															id="protocol-time-s"
															defaultValue=""
															size="small"
														/>
													)}
													name="meeting_time_start"
													control={control}
												/>
											</div>
											<div className="tt-flex tt-items-center tt-gap-8">
												<span className="tt-text-14  tt-mb-4">{t('till')}</span>
												<Controller
													render={({ field: { value, onChange } }) => (
														<TextField
															value={value}
															onChange={onChange}
															helperText={errors?.meeting_time_end?.message}
															error={!!errors.meeting_time_end}
															type="time"
															hiddenLabel
															className="dialig-input"
															id="protocol-time-po"
															defaultValue=""
															size="small"
														/>
													)}
													name="meeting_time_end"
													control={control}
												/>
											</div>
										</div>
									</div>
								</div>
								<InputContainer label={t('meeting_location')} error={errors?.place}>
									<Controller
										render={({ field: { value, onChange } }) => (
											<PlacesChoice placeholder={t('meeting_location')} value={value} onChange={onChange} />
										)}
										name="place"
										control={control}
									/>
								</InputContainer>

								<InputContainer label={t('participants')} error={errors?.users}>
									<Controller
										render={({ field: { value, onChange } }) => (
											<UsersChoice
												placeholder={t('participants')}
												error={errors?.hashtags}
												value={value}
												onChange={onChange}
											/>
										)}
										name="users"
										control={control}
									/>
								</InputContainer>
								<InputContainer label={t('project')} error={errors?.project}>
									<Controller
										render={({ field: { value, onChange } }) => (
											<ProjectsChoice placeholder={t('project')} value={value} onChange={onChange} />
										)}
										name="project"
										control={control}
									/>
								</InputContainer>
							</div>
							<div className="tt-relative">
								<div className="tt-py-16 tt-my-24">
									<Divider />
								</div>
								<Controller
									render={({ field: { value, onChange } }) => (
										<>
											{errors?.decisionsTaken?.message && (
												<p className="tt-text-12 tt-text-red-600">{errors?.decisionsTaken?.message}</p>
											)}
											<div className="tt-flex tt-py-8 tt-top-56 tt-border-b tt-border-gray-200 tt-sticky tt-bg-white tt-items-center tt-gap-2 tt-justify-between tt-z-10">
												{value.length > 0 ? (
													<div className="tt-flex tt-items-center tt-gap-4">
														<span className="tt-text-18 tt-font-medium">{t('meeting_decisions')}</span>{' '}
														<span className="tab-badge tt-bg-primary">{value.length}</span>
													</div>
												) : (
													<span className="tt-text-18 tt-font-medium">{t('meeting_decisions')}</span>
												)}

												<ProtocolAddButton value={value} onChange={onChange} project={project} />
											</div>

											<ProtocolDecision
												project={project}
												setError={setError}
												errors={errors?.decisionsTaken}
												onChange={onChange}
												value={value}
											/>
										</>
									)}
									name="decisionsTaken"
									control={control}
								/>
							</div>
						</div>
					</div>
				</form>
			}
		/>
	)
}

export default ProtocolCreate
