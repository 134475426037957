import React from 'react'
import AddIcon from '@mui/icons-material/Add'
import { Button } from '@mui/material'
import { useDispatch } from 'react-redux'
import { dialogActions } from 'src/old-app/store/dialogSlice'
import { useTranslation } from 'react-i18next'

const StartMyProject = () => {
	const { t } = useTranslation()
	const dispath = useDispatch()

	const openDialogBoardCreate = () => {
		dispath(dialogActions.setDialog(true))
		dispath(dialogActions.setComponent('my-task-create'))
	}

	return (
		<div className="tt-flex tt-flex-col tt-items-center tt-w-full tt-px-16 tt-py-24 tt-pb-42 tt-gap-4">
			<h2 className="tt-text-22 tt-font-semibold">{t('personal_tasks')}</h2>
			<p className="tt-mb-8 tt-text-16  tt-font-medium">{t('you_dont_have_personal_tasks')}</p>
			<Button
				onClick={openDialogBoardCreate}
				startIcon={<AddIcon />}
				className="tt-bg-primary hover:tt-bg-primary-600 tt-text-white  tt-min-h-32 tt-h-32 tt-px-16"
			>
				{t('create')}
			</Button>
		</div>
	)
}

export default StartMyProject
