/* eslint-disable prettier/prettier */
/* eslint-disable import/order */
import React, { useEffect, useState } from 'react'
import { Checkbox, FormControlLabel, TextField } from '@mui/material'
import { Controller, useForm } from 'react-hook-form'
import DialogFooter from '../DialogFooter'
import DialogHeader from '../DialogHeader'
import DialogMain from '../DialogMain'

import { yupResolver } from '@hookform/resolvers/yup'
import * as yup from 'yup'
import { useDispatch, useSelector } from 'react-redux'
import { showMessage } from 'src/old-app/store/fuse/messageSlice'
import { dialogActions } from 'src/old-app/store/dialogSlice'

import {
	useCreateHastagTaskMutation,
	useCreateTaskDeadlineMutation,
	useCreateTaskHashtagLinkMutation,
	useCreateTaskMutation,
	useCreateTaskSubscriberMutation,
} from 'src/old-app/service/Tasks'
import { getProject, getProjects, getProjectsUsers } from 'src/old-app/store/projectsSlice'
import { getUsers } from 'src/old-app/store/userSlice'
import Files from './Files'
import { fileUpload, useCreateFileMutation } from 'src/old-app/service/File'
import { getTask, getTaskTypes } from 'src/old-app/store/tasksSlice'
import { useCreateFileTaskMutation } from 'src/old-app/service/TasksFile'
import { useNavigate, useParams } from 'react-router-dom'
import { interfaceActions } from 'src/old-app/store/interfaceSlice'
import getMentionIds from 'src/old-app/utils/getMentionIds'
import MD from './MD'
import TaskParams from './TaskParams'

import { useGetUser } from 'src/shared/hooks'
import { InputContainer, UsersChoice } from 'src/shared/components'
import ProjectUsersChoice from '../../../../shared/components/project-users-choice'
import TaskTagsChoice from '../../../../shared/components/task-tags-choice'
import TaskTypesChoice from '../../../../shared/components/task-types-choice'
import TaskPriorityChoice from '../../../../shared/components/task-priority-choice'
import TaskTemplatesChoice from '../../../../shared/components/task-templates-choice'
import { checkFileSize } from 'src/shared/lib/checkFileSize'
import { useTranslation } from 'react-i18next'
import { ContractDirectorySelect } from 'src/@features/task'
import clsx from 'clsx'
import LocalTemporaryChangesController from '../../task/components/LocalTemporaryChangesController'
import { getStatuses } from 'src/old-app/store/tasksSlice'
import TaskStatusesChoice from 'src/shared/components/task-statuses-choice'
import DatePickerInput from '../../../../shared/components/date-picker-input/DatePickerInput'
import dayjs from 'dayjs'

const CreateTask = ({ isMy, isEpic }) => {
	const { t } = useTranslation()
	const schema = yup.object().shape({
		title: yup.string().required(t('task_title_is_required')),
		assignees: yup.array().min(1, t('performer_is_required')),
		deadline: yup.date().typeError(t('invalid_date')),
		type: yup.object().required(t('task_type_is_required')).nullable(),
		status: yup.object().required(t('task_status_is_required')).nullable(),
	})
	const mySchema = yup.object().shape({
		title: yup.string().required(t('task_title_is_required')),
		deadline: yup.date().typeError(t('invalid_date')),
		type: yup.object().required(t('task_type_is_required')).nullable(),
		status: yup.object().required(t('task_status_is_required')).nullable(),
		params: yup.array().of(
			yup.object().shape({
				value: yup.string().required(t('title_is_required')),
				label: yup.string().required(t('value_is_required')),
			})
		),
	})

	const taskStatuses = useSelector(getStatuses)
	const projectsUsers = useSelector(getProjectsUsers)
	const project = useSelector(getProject)
	const taskTypes = useSelector(getTaskTypes)
	const curTask = useSelector(getTask)
	const user = useGetUser()
	const dispatch = useDispatch()

	const isMyTask = isMy || project?.id === user?.id

	const {
		setValue,
		handleSubmit,
		register,
		control,
		getValues,
		watch,
		formState: { errors },
	} = useForm({
		defaultValues: {
			files: [],
			subscribers: [],
			assignees: [],
			hashtags: [],
			params: [],
			type: null,
			status: null,
			template: null,
			contract_type: null,
			is_contract: false,
			need_report: false,
			need_deadline_confirme: false,
		},
		resolver: yupResolver(isMyTask ? mySchema : schema),
	})

	/////////////// Функционал для временного локального сохранения данных из полей ///////////////
	const setValueHandler = (fieldName, fieldValue) => setValue(fieldName, fieldValue)
	const getValueHandler = (fieldName) => getValues(fieldName)
	///////////////////////////////////////////////////////////////////////////////////////////////

	/////////////////// Перечисление и описание полей для локального сохранения ///////////////////
	const fieldsForTemporaryStorage = [
		{ fieldName: 'title', fieldTitle: t('title') },
		{ fieldName: 'description', fieldTitle: t('description') },
		{ fieldName: 'result_representation', fieldTitle: t('expected_results') },
	]
	///////////////////////////////////////////////////////////////////////////////////////////////

	// const [drag, setDrag] = useState(false)
	const [createTask, taskResult] = useCreateTaskMutation()
	const [createHastagTask, projectResult] = useCreateHastagTaskMutation()
	const [createFile, fileResult] = useCreateFileMutation()
	const [createTaskHashtagLink] = useCreateTaskHashtagLinkMutation()
	const [createTaskDeadline, deadlineResult] = useCreateTaskDeadlineMutation()
	const [createFileTask, fileTaskResult] = useCreateFileTaskMutation()
	const [createTaskSubscriber, subsResult] = useCreateTaskSubscriberMutation()
	const [isDisabled, setIsDisabled] = useState(false)

	const template = watch('template')

	useEffect(() => {
		setValue('is_contract', project?.isContract)
	}, [project?.isContract])

	useEffect(() => {
		if (isMyTask && user) {
			const curUser = projectsUsers?.find((projectUser) => projectUser?.user_id === user?.id)?.user
			setValue('assignees', [curUser])
		}
	}, [isMyTask, user])

	useEffect(() => {
		if (template) {
			const p = []

			Object.entries(template?.params_template ?? {}).map(([field, value], index) => {
				p.push({
					id: index,
					label: field,
					order: index,
					value,
				})
			})

			setValue('params', p)
			setValue('description', template?.description)
		}
	}, [template])

	//Название статуса не подлежит локализации
	const defaultInitialStatus = {
		status: 'К выполнению',
		project_status_id: null,
	}

	useEffect(() => {
		if (!taskStatuses || !taskStatuses?.length) {
			setValue('status', defaultInitialStatus)
			return
		}
		if (project?.with_administration && !taskStatuses.some((status) => !status.is_final)) {
			setValue('status', defaultInitialStatus)
			return
		}
		setValue(
			'status',
			taskStatuses.find((status) => status.is_initial) ??
				taskStatuses.find((status) => !status.is_request && !status.is_final) ??
				taskStatuses.find((status) => !status.is_final)
		)
	}, [taskStatuses])

	useEffect(() => {
		function showErrorMessage(errorDescription = null) {
			dispatch(interfaceActions?.setIsLoading(false))
			setIsDisabled(false)
			dispatch(
				showMessage({
					message: errorDescription ?? t('error'),
					autoHideDuration: 2000,
					anchorOrigin: {
						vertical: 'top',
						horizontal: 'top-right',
					},
					variant: 'error',
				})
			)
		}

		if (taskResult?.isError) {
			showErrorMessage(taskResult?.error?.data?.error)
		}
		if (projectResult?.isError) {
			showErrorMessage(projectResult?.error?.data?.error)
		}
		if (fileResult?.isError) {
			showErrorMessage(fileResult?.error?.data?.error)
		}
		if (deadlineResult?.isError) {
			showErrorMessage(deadlineResult?.error?.data?.error)
		}
		if (fileTaskResult?.isError) {
			showErrorMessage(fileTaskResult?.error?.data?.error)
		}
		if (subsResult?.isError) {
			showErrorMessage(subsResult?.error?.data?.error)
		}
	}, [taskResult, projectResult, fileResult, deadlineResult, subsResult, fileTaskResult])

	const createTasksHandler = async (data) => {
		try {
			dispatch(interfaceActions.setIsLoading(true))
			setIsDisabled(true)

			for (const file of data.files) {
				if (!checkFileSize(file.size)) {
					dispatch(interfaceActions.setIsLoading(false))
					setIsDisabled(false)
					dispatch(
						showMessage({
							message: t('maximum_file_size'),
							anchorOrigin: {
								vertical: 'top',
								horizontal: 'top-right',
							},
							variant: 'warning',
						})
					)
					return
				}
			}

			const mentionsIds = data?.description ? getMentionIds(data?.description) : []

			const projectId = data?.project?.id
			const assigneeIds = data?.assignees?.map((item) => item.id)
			const subscriberIds = data?.subscribers?.map((item) => item.id)

			const myReduceParams = data.params
				.filter((item) => item.label && item.value)
				.reduce((acc, object) => {
					const field = object.label
					acc[field] = object.value

					return acc
				}, {})

			const taskDeadline = data?.deadline ? dayjs(data?.deadline).format('YYYY-MM-DD') + 'T00:00:00+00:00' : undefined

			const taskBody = {
				description: data?.description,
				result_representation: data?.result_representation,
				title: data?.title,
				contract_type: data?.contract_type?.id,
				selected_param: null,
				type_id: data?.type?.id,
				project_id: projectId,
				assignee_id: isMyTask ? [user.id] : assigneeIds,
				need_report: isMyTask ? false : data?.need_report,
				need_deadline_confirm: isMyTask ? false : data?.need_deadline_confirm,
				mentions_user_ids: mentionsIds,
				task_priority_id: data?.priority?.id,
				params: myReduceParams,
				parent_task_id: isEpic && curTask ? curTask?.id : undefined,
				deadline: taskDeadline,
				project_status_id: data?.status?.id,
			}

			const taskData = await createTask(taskBody)
			if (!taskData.data) return

			const tasks = taskData?.data?.data ?? []

			await Promise.all(
				tasks.map(async (item) => {
					const files = []
					const taskId = item.id

					await Promise.all(
						await data.hashtags.map(async (hashtag) => {
							await createTaskHashtagLink({ id: item.id, hashtagId: hashtag.id })
						})
					)

					await Promise.all(
						await data.files.map(async (fileItem) => {
							const fileData = await fileUpload(fileItem)
							files.push(fileData)
							if (!fileData.data) return
							const fileId = fileData?.data?.id
							await createFileTask({
								file_id: fileId,
								task_id: taskId,
							})
						})
					)
					await Promise.all(
						await subscriberIds.map(async (subscriberId) => {
							await createTaskSubscriber({ task_id: taskId, user_id: subscriberId })
						})
					)
				})
			)

			dispatch(dialogActions.setDialog(false))
			dispatch(
				showMessage({
					message: t('successfully_created.task'),
					autoHideDuratigon: 1000,
					anchorOrigin: {
						vertical: 'top',
						horizontal: 'top-right',
					},
					variant: 'success',
				})
			)
			dispatch(interfaceActions.setIsLoading(false))

			////////////////////// Очистка временных локальных сохранений //////////////////////
			if (!taskData?.isError) {
				window.localStorage.removeItem(project?.id)
			}
			////////////////////////////////////////////////////////////////////////////////////
		} catch (error) {
			dispatch(interfaceActions?.setIsLoading(false))
			setIsDisabled(false)
			dispatch(
				showMessage({
					message: error?.messsage ?? t('error'),
					autoHideDuration: 500,
					anchorOrigin: {
						vertical: 'top',
						horizontal: 'top-right',
					},
					variant: 'error',
				})
			)
		}
	}

	useEffect(() => {
		if (taskTypes.length !== 0) {
			setValue('type', taskTypes[0])
		}
		if (project) {
			setValue('project', project)
		}
	}, [taskTypes, project])

	const dragStartHandler = (e) => {
		e.preventDefault()
	}

	const dragLeaveHandler = (e) => {
		e.preventDefault()
	}

	const dropHandler = (e) => {
		e.preventDefault()
		const newfiles = [...e.dataTransfer.files]
		const oldFiles = getValues('files')
		const files = [...oldFiles, ...newfiles]
		setValue('files', files)
	}

	const checkStatusDisability = (status) => {
		if (!project?.with_administration) return false
		return status?.is_final || status?.is_request
	}

	return (
		<form
			onDragStart={dragStartHandler}
			onDragLeave={dragLeaveHandler}
			onDragOver={dragStartHandler}
			onDrop={dropHandler}
			onSubmit={handleSubmit(createTasksHandler)}
			className="tt-w-[800px] tt-h-full tt-relative tt-overflow-x-hidden"
		>
			<DialogHeader title={t('new.task')} />
			<DialogMain>
				<div className="tt-col-span-6">
					<LocalTemporaryChangesController
						id={`${project?.id}_controller`}
						localStorageKey={project?.id}
						observableFields={fieldsForTemporaryStorage}
						setFieldValue={setValueHandler}
						getFieldValue={getValueHandler}
					/>
				</div>
				<div className="tt-grid tt-grid-cols-6 tt-gap-8">
					<InputContainer className="tt-col-span-6" label={t('title')} error={errors?.title}>
						<TextField
							id="task-title"
							{...register('title')}
							error={!!errors.title}
							placeholder={t('title')}
							className="dialig-input"
							size="small"
						/>
					</InputContainer>
					<InputContainer
						label={t('performers')}
						className={clsx(project?.isContract ? 'tt-col-span-6' : 'tt-col-span-3')}
						error={errors?.assignees}
					>
						<Controller
							render={({ field: { value, onChange } }) => (
								<ProjectUsersChoice
									getOptionDisabled={isMyTask ? (option) => option?.id === user?.id : undefined}
									disabled={isMyTask}
									placeholder={t('performers')}
									projectId={project?.id}
									value={value}
									onChange={onChange}
								/>
							)}
							name="assignees"
							control={control}
						/>
					</InputContainer>
					<InputContainer
						label={t('deadline')}
						className={clsx(project?.isContract ? 'tt-col-span-2' : 'tt-col-span-3')}
						error={errors?.deadline}
					>
						<Controller
							render={({ field: { value, onChange } }) => <DatePickerInput value={value} onChange={onChange} />}
							name="deadline"
							control={control}
						/>
					</InputContainer>

					{project?.isContract && (
						<InputContainer className="tt-col-span-2" label={t('works_type')} error={errors?.contract_type}>
							<Controller
								render={({ field: { value, onChange } }) => (
									<ContractDirectorySelect onChange={onChange} placeholder={t('works_type')} value={value} />
								)}
								name="contract_type"
								control={control}
							/>
						</InputContainer>
					)}
					<InputContainer
						className={clsx(project?.isContract ? 'tt-col-span-2' : 'tt-col-span-3')}
						label={t('template')}
						error={errors?.template}
					>
						<Controller
							render={({ field: { value, onChange } }) => (
								<TaskTemplatesChoice
									project_id={project?.id}
									placeholder={t('template')}
									onChange={onChange}
									value={value}
								/>
							)}
							name="template"
							control={control}
						/>
					</InputContainer>
					<InputContainer
						className={clsx(project?.isContract ? 'tt-col-span-2' : 'tt-col-span-3')}
						label={t('priority')}
						error={errors?.priority}
					>
						<Controller
							render={({ field: { value, onChange } }) => (
								<TaskPriorityChoice
									placeholder={t('priority')}
									projectId={project?.id}
									value={value}
									onChange={onChange}
								/>
							)}
							name="priority"
							control={control}
						/>
					</InputContainer>

					<InputContainer
						className={clsx(project?.isContract ? 'tt-col-span-2' : 'tt-col-span-3')}
						label={t('type')}
						error={errors?.type}
					>
						<Controller
							render={({ field: { value, onChange } }) => (
								<TaskTypesChoice placeholder={t('type')} value={value} onChange={onChange} />
							)}
							name="type"
							control={control}
						/>
					</InputContainer>

					<InputContainer
						className={clsx(project?.isContract ? 'tt-col-span-2' : 'tt-col-span-3')}
						label={t('status')}
						error={errors?.status}
					>
						<Controller
							render={({ field: { value, onChange } }) => (
								<TaskStatusesChoice
									placeholder={t('status')}
									disabled={!taskStatuses || taskStatuses?.length < 2}
									value={value}
									taskStatuses={taskStatuses}
									onChange={onChange}
									checkStatusDisability={checkStatusDisability}
								/>
							)}
							name="status"
							control={control}
						/>
					</InputContainer>

					<InputContainer className="tt-col-span-6" label={t('description')} error={errors?.description}>
						<Controller
							render={({ field: { onChange, value } }) => <MD value={value} onChange={onChange} />}
							name="description"
							control={control}
						/>
					</InputContainer>

					<InputContainer label={t('tags')} className="tt-col-span-3" error={errors?.hashtags}>
						<Controller
							render={({ field: { value, onChange } }) => (
								<TaskTagsChoice projectId={project?.id} placeholder={t('tags')} value={value} onChange={onChange} />
							)}
							name="hashtags"
							control={control}
						/>
					</InputContainer>

					<InputContainer label={t('observers')} className="tt-col-span-3" error={errors?.subscribers}>
						<Controller
							render={({ field: { value, onChange } }) => (
								<UsersChoice placeholder={t('observers')} value={value} onChange={onChange} />
							)}
							name="subscribers"
							control={control}
						/>
					</InputContainer>

					<InputContainer className="tt-col-span-6" label={t('expected_results')} error={errors?.result_representation}>
						<TextField
							multiline
							{...register('result_representation')}
							error={!!errors.result_representation}
							placeholder={t('expected_results')}
							className="dialig-input"
							size="small"
						/>
					</InputContainer>

					<Controller
						render={({ field: { value, onChange } }) => (
							<Files
								className="tt-col-span-6 tt-z-0"
								id="task-files"
								onChange={onChange}
								value={value}
								label={t('files')}
							/>
						)}
						name="files"
						control={control}
					/>

					<Controller
						render={({ field: { value, onChange } }) => (
							<TaskParams className="tt-col-span-6" errors={errors} value={value} onChange={onChange} />
						)}
						name="params"
						control={control}
					/>

					{!isMyTask && (
						<div className="tt-col-span-6">
							{project?.with_administration && (
								<Controller
									render={({ field: { value, onChange } }) => (
										<FormControlLabel
											id="task-need_report"
											control={<Checkbox checked={value} onChange={onChange} />}
											label={t('report_is_required')}
										/>
									)}
									name="need_report"
									control={control}
								/>
							)}
						</div>
					)}
				</div>
			</DialogMain>
			<DialogFooter isDisabled={isDisabled} />
		</form>
	)
}

export default CreateTask
