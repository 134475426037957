import { useForm } from 'react-hook-form'

import { yupResolver } from '@hookform/resolvers/yup'
import IconButton from '@mui/material/IconButton'
import ListItemIcon from '@mui/material/ListItemIcon'
import ListItemText from '@mui/material/ListItemText'
import Menu from '@mui/material/Menu'
import MenuItem from '@mui/material/MenuItem'
import Typography from '@mui/material/Typography'
import { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import * as yup from 'yup'
import _ from 'src/@lodash'
import { Box } from '@mui/system'
import FuseSvgIcon from 'src/@fuse/core/FuseSvgIcon'
import { scrumboardActions } from 'src/old-app/store/scrumboardSlice'
import { useDeleteStatusTaskMutation } from 'src/old-app/service/Tasks'
import { tasksActions } from 'src/old-app/store/tasksSlice'
import { dialogActions } from 'src/old-app/store/dialogSlice'
import { useSelector } from 'react-redux'
import { interfaceActions } from 'src/old-app/store/interfaceSlice'
import { getProjectUserRole, getProject } from 'src/old-app/store/projectsSlice'
import { useProjectShow } from '../../../../../entities/project'
import { ConfirmDelete } from 'src/shared/components'
import { useTranslation } from 'react-i18next'
import CommentedDisability from 'src/@shared/lib/CommentedDisability'
// import { removeList, updateList } from '../../store/listsSlice';

/**
 * Form Validation Schema
 */
const schema = yup.object().shape({
	title: yup.string().required('You must enter a title'),
})

function BoardListHeader(props) {
	const { t } = useTranslation()
	const { list, length, totalCards, listId } = props
	const dispatch = useDispatch()
	const project = useSelector(getProject)
	const isUser = useSelector(getProjectUserRole)
	const [deleteStatusTask] = useDeleteStatusTaskMutation()
	const projectShow = useProjectShow()
	const [anchorEl, setAnchorEl] = useState(null)
	const [formOpen, setFormOpen] = useState(false)

	const isCommittee = projectShow?.isCommittee

	const { control, formState, handleSubmit, reset, setValue } = useForm({
		mode: 'onChange',
		defaultValues: {
			title: '',
		},
		resolver: yupResolver(schema),
	})

	useEffect(() => {
		if (!formOpen) {
			reset({
				title: list.title,
			})
		}
	}, [formOpen, reset, list.title])

	useEffect(() => {
		if (formOpen && anchorEl) {
			setAnchorEl(null)
		}
	}, [anchorEl, formOpen])

	function handleMenuClick(event) {
		setAnchorEl(event.currentTarget)
	}

	function handleMenuClose() {
		setAnchorEl(null)
	}

	function handleOpenForm(ev) {
		ev.stopPropagation()
		setFormOpen(true)
	}

	function handleCloseForm() {
		setFormOpen(false)
	}

	const onSubmit = async (newData) => {
		handleCloseForm()
	}

	const deleteList = async () => {
		dispatch(interfaceActions.setIsLoading(true))
		await deleteStatusTask(listId)
		dispatch(interfaceActions.setIsLoading(false))
		dispatch(scrumboardActions.deleteOneList(listId))
		dispatch(tasksActions.deleteStatus(listId))
		handleMenuClose()
	}
	const editList = () => {
		dispatch(tasksActions.getStatus(list))
		dispatch(dialogActions.setDialog(true))
		dispatch(dialogActions.setComponent('edit-status'))
		handleMenuClose()
	}

	const stateDefaultValue = () => {
		if (list?.is_request) {
			return t('statuses.request')
		}
		if (list?.is_final) {
			return t('statuses.final')
		}
		if (list?.is_initial) {
			return t('statuses.initial')
		}
	}

	const deleteColumnIsDisabled = project?.with_administration
		? list?.is_request || list?.is_final || list?.is_initial
		: false

	return (
		<div {...props.handleProps}>
			<div className="tt-flex tt-items-center tt-justify-between tt-h-52 sm:tt-h-56 tt-px-12">
				<div className="tt-flex tt-items-center tt-min-w-0">
					<div className="tt-flex tt-items-center tt-gap-7 tt-relative">
						<div style={{ background: list.color }} className="tt-w-7 tt-h-7 tt-rounded-full" />
						<span className="tt-absolute -tt-top-12 tt-left-14 tt-text-10 tt-text-gray-500">{stateDefaultValue()}</span>
						<Typography className="tt-text-14 tt-font-medium   tt-max-w-216 tt-truncate" onClick={handleOpenForm}>
							{list.status}
						</Typography>
					</div>
				</div>
				<div className="tt-flex tt-items-center">
					<Box className="tt-flex tt-items-center  tt-bg-gray-200 tt-text-gray-600 tt-justify-center tt-min-w-24 tt-h-24 tt-mx-4 tt-px-8 tt-text-sm tt-font-semibold tt-leading-24 tt-rounded-full">
						{length < totalCards ? `${length} ${t('of')} ${totalCards}` : `${length}`}
					</Box>
					{!isUser && !projectShow?.project?.isEnd && (
						<>
							<IconButton
								aria-owns={anchorEl ? 'actions-menu' : null}
								aria-haspopup="true"
								onClick={handleMenuClick}
								variant="outlined"
								size="small"
							>
								<FuseSvgIcon size={20}>heroicons-outline:dots-vertical</FuseSvgIcon>
							</IconButton>
							<Menu id="actions-menu" anchorEl={anchorEl} open={Boolean(anchorEl)} onClose={handleMenuClose}>
								<CommentedDisability
									optionDisabled={isCommittee && !deleteColumnIsDisabled}
									disabilityComment={isCommittee ? t('not_available_for_project_type') : ''}
								>
									<ConfirmDelete
										text={t('delete_status_dialog.annotation', { value: list.status })}
										onSuccess={deleteList}
										title={t('delete_status_dialog.header')}
										disabled={deleteColumnIsDisabled}
									>
										<MenuItem disabled={deleteColumnIsDisabled}>
											<ListItemIcon className="tt-min-w-40">
												<FuseSvgIcon>heroicons-outline:trash</FuseSvgIcon>
											</ListItemIcon>
											<ListItemText primary={t('delete')} />
										</MenuItem>
									</ConfirmDelete>
								</CommentedDisability>
								<CommentedDisability
									optionDisabled={isCommittee}
									disabilityComment={isCommittee ? t('not_available_for_project_type') : ''}
								>
									<MenuItem onClick={editList}>
										<ListItemIcon className="tt-min-w-40">
											<FuseSvgIcon>heroicons-outline:pencil</FuseSvgIcon>
										</ListItemIcon>
										<ListItemText primary={t('change')} />
									</MenuItem>
								</CommentedDisability>
							</Menu>
						</>
					)}
				</div>
			</div>
		</div>
	)
}

export default BoardListHeader
